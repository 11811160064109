<template>
    <div>
        <Header></Header>
        <div class="content">
            <div class="container">
                <div class="top_box">
                    <div class="xian"></div>
                    <span class="top_title">游戏版本选择</span>
                </div>
                <div class="bottom_box">
                    <div v-for="item in equipmentList" :key="item.code" @click="getInfo(item)" class="game_btn">{{item.name}}</div>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import Header from '../components/Header.vue';
import Article from '../components/Article.vue';
import {equipmentQueryInfo} from '@/api/http.js'
import { mapState } from 'vuex'
export default {
    name: 'Home',
    components: { Header, Article },
    computed:{
        ...mapState([
            'equipmentList'
        ]),
        v_id:{
            get(){
                return this.$store.state.v_id
            },
            set(val){
                return this.$store.state.v_id = val
            }
        },
        v_name:{
            get(){
                return this.$store.state.v_name
            },
            set(val){
                return this.$store.state.v_name = val
            }
        },
        Stdlist:{
            get(){
                return this.$store.state.Stdlist
            },
            set(val){
                return this.$store.state.Stdlist = val
            }
        },
        Monlist:{
            get(){
                return this.$store.state.Monlist
            },
            set(val){
                return this.$store.state.Monlist = val
            }
        },
        Maplist:{
            get(){
                return this.$store.state.Maplist
            },
            set(val){
                return this.$store.state.Maplist = val
            }
        },
        Npclist:{
            get(){
                return this.$store.state.Npclist
            },
            set(val){
                return this.$store.state.Npclist = val
            }
        }
    },
    data() {
        return {
            js_info:''
        }
    },
    methods:{
        getInfo(item){
            this.v_id = item.code
            localStorage.setItem('v_name',item.name)
            localStorage.setItem('v_id',item.code)
            const banben = localStorage.getItem('v_name')
            this.v_name = banben
            if(this.v_id){
                this.equipmentQueryInfo()
            }
        },
        loadScript(src) {
        // 删除旧脚本（如果存在）
            const existingScript = document.getElementById('kjk');
            if (existingScript) {
                existingScript.remove();
            }
            var timestamp = new Date().getTime();
            const script = document.createElement('script');
            script.id = 'kjk';
            script.src = src + "?v=" + timestamp;
            script.onload = () => {
                // this.currentComponent = 'Home'; // 加载成功后，设置当前组件为你要加载的组件
                localStorage.setItem('Stdlist',JSON.stringify(Stdlist))
                localStorage.setItem('Monlist',JSON.stringify(Monlist))
                localStorage.setItem('Maplist',JSON.stringify(Maplist))
                localStorage.setItem('Npclist',JSON.stringify(Npclist))
                this.Stdlist = JSON.parse(localStorage.getItem('Stdlist'))
                this.Monlist = JSON.parse(localStorage.getItem('Monlist'))
                this.Maplist = JSON.parse(localStorage.getItem('Maplist'))
                this.Npclist = JSON.parse(localStorage.getItem('Npclist'))
                this.$router.push({
                    path:'/article'
                })
            };
            document.head.appendChild(script);
            const url = localStorage.setItem('equipment_url',src)
        },
        equipmentQueryInfo(){
            equipmentQueryInfo({
                code:this.v_id
            }).then((res)=>{
                if(res.code == 200){
                    this.js_info = res.data.equipment_url
                    this.loadScript(this.js_info)
                    const url = localStorage.getItem('equipment_url')
                }
            }).catch((err)=>{
                console.log("捕获异常",err)
            })
        },
       
    },
    
}
</script>

<style scoped>
.content {
    width: 100%;
    height: 100%;
}

.top_box {
    display: flex;
    align-items: center;
    position: relative;
    top: 60px;
}

.top_title {
    position: absolute;
    margin-left: 20px;
    padding: 0 10px;
    font-size: 20px;
    font-weight: 300;
    background: #fff;
}

.xian {
    width: 100%;
    background: #eee;
    height: 1px;
}

.game_btn {
    display: inline-block;
    vertical-align: middle;
    height: 38px;
    line-height: 38px;
    border: 1px solid transparent;
    padding: 0 18px;
    background-color: #009688;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s;
    margin: 0 10px 5px;
}
.game_btn:hover{
    transition: all 0.5s;
    filter: brightness(1.2);
}
.bottom_box{
    position: relative;
    top: 100px;
    display: flex;
    flex-wrap: wrap;
}
</style>