<template>
    <div>
        <Header></Header>
        <div class="content">
            <div class="container">
                <div class="search_box row">
                    <div class="col-10">
                        <input type="text" v-model="seachValue" class="sousuo" placeholder="请输入装备关键字(删除所有关键字可查询所有物品)">
                    </div>
                    <div class="col-2">
                        <div class="search_btn">
                            搜索物品装备
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class=" col-3">
                    <div class="top_title text_ellipsis">所在地图名称（没有地图说明这个怪物不刷出）
                    </div>
                    <div class="wp_box">
                        <ul >
                            <li v-show="item.mon != -1" v-for="(item, index) in pool" :key="index"
                                @click="SearchNPC(item.npc, item.path, item.name,item.mon)">
                                <a class="hover_list">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
               
                <div class=" col-3">
                    <div class="top_title text_ellipsis"><span class="name_text">{{ map_name }}</span>
                        会刷这些怪物（点击怪物查看爆什么）
                    </div>
                    <div class="wp_box">
                        <ul >
                            <ul>
                                <li 
                                    v-for="(item, index) in m_mon" :key="index"
                                    @click="MadeSearch(Monlist[item].map, Monlist[item].bot, Monlist[item].name,Monlist[item].std)"><a class="hover_list">{{ Monlist[item].name
                                    }}</a>
                                </li>
                                <li class="hover_list" v-if="m_mon.length == 0 && map_name">没有找到！</li>
                            </ul>

                        </ul>
                    </div>
                </div>
                <div class=" col-3">
                    <div class="top_title text_ellipsis"><span class="name_text">{{ npc_name }}</span>会爆这些东西(没有信息说明这个怪没掉落)</div>
                    <div class="wp_box">
                        <ul>
                            <li v-for="(item, index) in std" :key="index">
                                <a class="hover_list">{{ Stdlist[item].name }}</a>
                            </li>
                            <li class="hover_list" v-if="std.length == 0 && npc_name">没有找到！</li>
                        </ul>
                    </div>

                </div>

                <div class=" col-3">
                    <div class="top_title text_ellipsis"><span class="name_text">{{ map_name }}</span> 跑图流程（没有信息说明此地图是触发进入）
                    </div>
                    <div class="wp_box">
                        <ul class="path_ul" >
                            <li  v-for="(item, index) in map_npc"
                                :key="index" class="path_title path_bord">
                                <span class="path_one">NPC直传</span>
                                {{ Npclist[item].name + ' ' + '【' + Npclist[item].mname + '(' + Npclist[item].mxy + ')' + '】' }}
                            </li>
                            <ul class="ptlx">
                                <li v-for="(i, index) in map_path" :key="index" class="path_title path_bord">
                                    <span class="path_one">跑图路线</span>
                                    {{ i }}
                                </li>
                                <h5 v-if="path_flag" class="path_title path_bord">
                                    <span class="path_one">跑图路线</span>
                                    没有找到！
                                </h5>
                            </ul>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from './Header.vue';
import { mapState } from 'vuex';
export default {
    name: 'Map',
    components:{Header},
    data() {
        return {
            npc_flag: false, //NPC/怪物查询
            mon: [], //查询对应的怪物和NPC
            std: [], //查询对应的怪物和NPC
            s_npc: [],
            m_map: [],//怪物列表查询路线
            m_mon:[],//地图列表中的mon查怪物列表的map
            map_flag: false,//地图列表变量
            npc_give: [],
            map_bot: [],
            map_npc: [],//NPC列表对应地图列表查询
            map_path: [],
            path_flag: false,
            zb_name: '',
            npc_name: '',
            map_name: '',
            seachValue: '',
        }
    },
    computed: {
        pool() {
            if (this.seachValue) {
                return this.Maplist.filter(game => {
                    return game.name.toLowerCase().includes(this.seachValue.toLowerCase());
                });
            } else {
                return this.Maplist;
            }
        },
        ...mapState([
            'Stdlist',
            'Monlist',
            'Maplist',
            'Npclist'
        ])
    },
    methods: {
        // 接收现实NPC和怪物
        MadeSearch(map, bot, name,std) {
            // 初始化
            this.m_map = []
            this.map_bot = []
            this.std = []
            this.npc_name = name
            if (map != -1) {
                this.m_map = map.split(",")
            } else {

            }

            if(std != -1){
                if (std.includes(',')) {
                    this.std = std.split(",")
                } else {
                    this.std = std.split()
                }
            }
            if (bot != -1) {
                if (bot.includes(',')) {
                    this.map_bot = bot.split(",")
                } else {
                    this.map_bot = bot.split()
                }
            }
        },
        SearchNPC(n, path, name,mon) {
            // 初始化
            this.map_npc = []
            this.map_path = []
            this.m_mon = []
            this.path_flag = false
            this.map_name = name
            if (n != -1) {
                if (n.includes(',')) {
                    this.map_npc = n.split(",")
                } else {
                    this.map_npc = n.split()
                }
            }
            if(mon != -1){
                if (mon.includes(',')) {
                    this.m_mon = mon.split(",")
                } else {
                    this.m_mon = mon.split()
                }
            }
            if (path == '没有找到！') {
                this.path_flag = true
            } else {
                if (path.includes(',')) {
                    this.map_path = path.split(",")
                } else {
                    this.map_path = path.split()
                }
            }
        }
    },
    mounted() {
       
    },
    
}
</script>

<style scoped>
ul {
    margin: 0;
    padding: 0;
}

.content {
    padding: 20px 50px;

}

.row>div {
    position: relative;
}

.top_box {
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
    position: relative;
    padding: 10px 15px;
    line-height: 24px;
    margin: 15px 0;
}

.top_box li {
    padding-top: 8px;
}

.top_title {
    font-size: 14px;
    font-weight: bolder;
    color: #007DDB;
    position: absolute;
    left: 0;
    top: -10px;
    text-align: center;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 0 10px;
}

.top_text {
    border-bottom: 1px solid #f8f8f8;
    border-radius: 2px 2px 0 0;
    font-size: 14px;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
}

.name_text {
    color: orangered;
}

.wp_box {
    max-height: 735px;
    min-height: 735px;
    overflow-y: scroll;
    border: 1px solid #eee;
    height: 100%;
    position: relative;
    padding: 10px 20px 0;
}

.ptlx {
    padding: 0;
    margin-top: 25px;
}

.wp_box ul li {
    list-style-type: decimal;
    list-style-position: inside;
}

.hover_list {
    color: #000;
    text-decoration: none;
    font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

.hover_list:hover {
    color: #007DDB;
}

.path_title {
    color: rgba(0, 0, 0, .85);
    font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

.path_bord {
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    position: relative;
}

.path_ul {
    margin-top: 15px;
}

.path_one {
    font-size: 14px;
    font-weight: bolder;
    color: #007DDB;
    position: absolute;
    left: 0;
    top: -10px;
    text-align: center;
    z-index: 10;
    left: 2%;
    background: #fff;
    padding: 0 10px;
}

/* 搜索 */
.search_box {
    margin-bottom: 20px;
}

.sousuo {
    width: 100%;
    height: 38px;
    line-height: 1.3;
    border: 1px solid #eee;
    background-color: #fff;
    color: rgba(0, 0, 0, .85);
    border-radius: 2px;
    padding-left: 10px;
}

.sousuo:focus {
    border-color: #16b777 !important;
    box-shadow: 0 0 0 3px rgba(22, 183, 119, .08);
}

input::placeholder {
    font-size: 14px;
}

.search_btn {
    display: inline-block;
    vertical-align: middle;
    height: 38px;
    line-height: 38px;
    border: 1px solid transparent;
    padding: 0 18px;
    background-color: #009688;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.5s;
}

.search_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

.search_box>div:nth-of-type(1) {
    padding-right: 6px;
}

.search_box>div:nth-of-type(2) {
    padding-left: 6px;
}</style>