<template>
    <div>
        <Header></Header>
        <div class="content">
            <div class="container">
                <div class="search_box row">
                    <div class="col-10">
                        <input type="text" v-model="seachValue" class="sousuo" placeholder="请输入装备关键字(删除所有关键字可查询所有物品)">
                    </div>
                    <div class="col-2">
                        <div class="search_btn">
                            搜索NPC
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class=" col-3">
                    <div class="top_title text_ellipsis">NPC列表（所有NPC都在这里）
                    </div>
                    <div class="wp_box">
                        <ul >
                            <li  v-for="(item, index) in pool"
                                :key="index"  @click="Obtain(item.take,item.give,item)">
                                <a class="hover_list">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class=" col-3">
                    <div class="top_title text_ellipsis"><span class="name_text">{{ npc_name }}</span> NPC消耗物品（没有的话可能是传送NPC）</div>
                    <div class="wp_box">
                        <ul>
                            <li v-for="(item, index) in npc_take" :key="index"
                                >
                                <a class="hover_list">{{ Stdlist[item].name }}</a>
                            </li>
                            <li class="hover_list" v-if="npc_take.length == 0 && npc_name">没有找到！</li>
                        </ul>
                    </div>
                </div>
                <div class=" col-3">
                    <div class="top_title text_ellipsis"><span class="name_text">{{ npc_name }}</span>
                        NPC给与的物品（有可能也是封号）
                    </div>
                    <div class="wp_box">
                        <ul >
                            <li  v-for="(item, index) in npc_give"
                                :key="index" class="path_title">
                                {{ Stdlist[item].name }}
                            </li>
                            <li class="hover_list" v-if="npc_give.length == 0 && npc_name">没有找到！</li>
                        </ul>
                    </div>
                </div>
                <div class=" col-3">
                    <div class="top_title text_ellipsis"><span class="name_text">{{ npc_name }}</span> 其他信息（详细信息）
                    </div>
                    <div class="wp_box">
                        <ul class="path_ul" >
                            <div v-if="npc_even.mname || npc_even.mxy"  class="path_title path_bord">
                                <span class="path_one">NPC信息</span>
                                所在地图：{{ npc_even.mname }}<br>
                                所在坐标：{{ npc_even.mxy }}
                            </div>
                            <div class="ptlx" v-if="npc_even.move">
                                <div class="path_title path_bord">
                                    <span class="path_one">可传地图</span>
                                    {{ npc_even.move }}
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from './Header.vue';
import { mapState } from 'vuex';
export default {
    name: 'Npc',
    components:{Header},
    data() {
        return {
            npc_flag: false, //NPC/怪物查询
            mon: [], //查询对应的怪物和NPC
            s_npc: [],
            m_map: [],//怪物列表查询路线
            map_flag: false,//地图列表变量
            npc_give: [],
            npc_take:[], //获取NPC列表take，查询NPC消耗物品
            map_bot: [],
            map_npc: [],//NPC列表对应地图列表查询
            map_path: [],
            npc_bool: false, // 控制NPC关联
            path_flag: false,
            zb_name: '',
            npc_name: '',
            map_name: '',
            seachValue: '',
            npc_even:[]
        }
    },
    computed: {
        pool() {
            if (this.seachValue) {
                return this.Npclist.filter(game => {
                    return game.name.toLowerCase().includes(this.seachValue.toLowerCase());
                });
            } else {
                return this.Npclist;
            }
        },
        ...mapState([
            'Stdlist',
            'Monlist',
            'Maplist',
            'Npclist'
        ])
    },
    methods: {
        // 获取NPC列表，NPC消耗物品
        Obtain(take,give,even){
            this.npc_take = []
            this.npc_give = []
            if(take != '没有'){
                if (take.includes(',')) {
                    this.npc_take = take.split(",")
                } else {
                    this.npc_take = take.split()
                }
            }
            if(give != '没有'){
                if (give.includes(',')) {
                    this.npc_give = give.split(",")
                } else {
                    this.npc_give = give.split()
                }
            }
            this.npc_even = even
            this.npc_name = even.name
        },
    },
    mounted() {
        
    },
    
}
</script>

<style scoped>
ul {
    margin: 0;
    padding: 0;
}

.content {
    padding: 20px 50px;

}

.row>div {
    position: relative;
}

.top_box {
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
    position: relative;
    padding: 10px 15px;
    line-height: 24px;
    margin: 15px 0;
}

.top_box li {
    padding-top: 8px;
}

.top_title {
    font-size: 14px;
    font-weight: bolder;
    color: #007DDB;
    position: absolute;
    left: 0;
    top: -10px;
    text-align: center;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 0 10px;
}

.top_text {
    border-bottom: 1px solid #f8f8f8;
    border-radius: 2px 2px 0 0;
    font-size: 14px;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
}

.name_text {
    color: orangered;
}

.wp_box {
    max-height: 735px;
    min-height: 735px;
    overflow-y: scroll;
    border: 1px solid #eee;
    height: 100%;
    position: relative;
    padding: 10px 20px 0;
}

.ptlx {
    padding: 0;
    margin-top: 25px;
}

.wp_box ul li {
    list-style-type: decimal;
    list-style-position: inside;
}

.hover_list {
    color: #000;
    text-decoration: none;
    font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

.hover_list:hover {
    color: #007DDB;
}

.path_title {
    color: rgba(0, 0, 0, .85);
    font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

.path_bord {
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    position: relative;
}

.path_ul {
    margin-top: 15px;
}

.path_one {
    font-size: 14px;
    font-weight: bolder;
    color: #007DDB;
    position: absolute;
    left: 0;
    top: -10px;
    text-align: center;
    z-index: 10;
    left: 2%;
    background: #fff;
    padding: 0 10px;
}

/* 搜索 */
.search_box {
    margin-bottom: 20px;
}

.sousuo {
    width: 100%;
    height: 38px;
    line-height: 1.3;
    border: 1px solid #eee;
    background-color: #fff;
    color: rgba(0, 0, 0, .85);
    border-radius: 2px;
    padding-left: 10px;
}

.sousuo:focus {
    border-color: #16b777 !important;
    box-shadow: 0 0 0 3px rgba(22, 183, 119, .08);
}

input::placeholder {
    font-size: 14px;
}

.search_btn {
    display: inline-block;
    vertical-align: middle;
    height: 38px;
    line-height: 38px;
    border: 1px solid transparent;
    padding: 0 18px;
    background-color: #009688;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.5s;
}

.search_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

.search_box>div:nth-of-type(1) {
    padding-right: 6px;
}

.search_box>div:nth-of-type(2) {
    padding-left: 6px;
}</style>