import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap';
// 引入路由
import router from './router';
import VueRouter from 'vue-router'

import store from './store'

// 全局引入二维码生成
import QRCode from 'qrcodejs2';


// 局部引入elementUI
// import 'element-ui/lib/theme-chalk/index.css';
// import elementComponents from '@/components/element.js'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.prototype.$qrCode = QRCode;
// 局部引入element ui 的message组件
import {Message} from "element-ui";
Vue.component(Message.name,Message)
Vue.prototype.$message = Message;
//  cancelToken中的cancel函数
Vue.$httpRequestList=[];
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
