//将拦截器整体导入
import request from '@/store/Interceptor.js'//导入已经写好的拦截器
 
// 封装所有的API接口
 
// export function Login(params){
//   console.log(params)
//   return request({
//     url:'/administrator/login',
//     method :'post',
//     params:params,
//   })
// }
 
// export function getRoles(params={}){
//   return request({
//     url:'/Roles/select',
//     method :'post',
//     params:params,
//   })
// }

// 装备查询列表
export function equipmentQueryList(){
    return request({
        url:'/api/game/equipmentQuery',
        method:'post',
    })
}
// 装备查询详情
export function equipmentQueryInfo(data){
    return request({
        url:'/api/game/equipmentQueryInfo',
        method:'post',
        data
    })
}