<template>
    <div class="nav">
        <div class="menu">
            <ul>
                <router-link active-class="active" :class="$route.path === '/' ? 'active' : ''" class="item" to="/home">
                    <li>首页</li>
                </router-link>
                <router-link active-class="active" class="item" to="/article">
                    <li>物品查询</li>
                </router-link>
                <router-link active-class="active" class="item" to="/monster">
                    <li>怪物查询</li>
                </router-link>
                <router-link active-class="active" class="item" to="/map">
                    <li>地图查询</li>
                </router-link>
                <router-link active-class="active" class="item" to="/npc">
                    <li>NPC查询</li>
                </router-link>
            </ul>
        </div>
        <div>
            <el-dropdown>
                <span class="el-dropdown-link">
                    当前版本【{{ v_name }}】<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="class_box">
                    <el-dropdown-item v-for="item in equipmentList" :key="item.code"
                        @click.native="getID(item.code, item.name)">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/dropdown.css';
import 'element-ui/lib/theme-chalk/dropdown-menu.css';
import 'element-ui/lib/theme-chalk/dropdown-item.css';
import { mapState } from 'vuex'
import { equipmentQueryInfo } from '@/api/http.js'
export default {
    name: 'Header',
    components: {
        'el-dropdown': Dropdown,
        'el-dropdown-menu': DropdownMenu,
        'el-dropdown-item': DropdownItem
    },
    computed: {
        ...mapState([
            'equipmentList'
        ]),
        v_id: {
            get() {
                return this.$store.state.v_id
            },
            set(val) {
                return this.$store.state.v_id = val
            }
        },
        v_name: {
            get() {
                return this.$store.state.v_name
            },
            set(val) {
                return this.$store.state.v_name = val
            }
        },
        Stdlist: {
            get() {
                return this.$store.state.Stdlist
            },
            set(val) {
                return this.$store.state.Stdlist = val
            }
        },
        Monlist: {
            get() {
                return this.$store.state.Monlist
            },
            set(val) {
                return this.$store.state.Monlist = val
            }
        },
        Maplist: {
            get() {
                return this.$store.state.Maplist
            },
            set(val) {
                return this.$store.state.Maplist = val
            }
        },
        Npclist: {
            get() {
                return this.$store.state.Npclist
            },
            set(val) {
                return this.$store.state.Npclist = val
            }
        }
    },
    data() {
        return {
        }
    },
    methods: {
        loadScript(src) {
            const existingScript = document.getElementById('kjk');
            if (existingScript) {
                existingScript.remove();
            }
            var timestamp = new Date().getTime();
            const script = document.createElement('script');
            script.id = 'kjk';
            script.src = src + "?v=" + timestamp;
            script.onload = () => {
                // this.currentComponent = 'Home'; // 加载成功后，设置当前组件为你要加载的组件

                

                localStorage.setItem('Stdlist', JSON.stringify(Stdlist))
                localStorage.setItem('Monlist', JSON.stringify(Monlist))
                localStorage.setItem('Maplist', JSON.stringify(Maplist))
                localStorage.setItem('Npclist', JSON.stringify(Npclist))
                this.Stdlist = JSON.parse(localStorage.getItem('Stdlist'))
                this.Monlist = JSON.parse(localStorage.getItem('Monlist'))
                this.Maplist = JSON.parse(localStorage.getItem('Maplist'))
                this.Npclist = JSON.parse(localStorage.getItem('Npclist'))
                this.$router.push({
                    path: '/article'
                })
            };
            document.head.appendChild(script);
            const url = localStorage.setItem('equipment_url', src)
        },
        equipmentQueryInfo() {
            equipmentQueryInfo({
                code: this.v_id
            }).then((res) => {
                if (res.code == 200) {
                    this.js_info = res.data.equipment_url
                    localStorage.setItem('equipment_url', this.js_info)
                    this.loadScript(this.js_info)

                }
            }).catch((err) => {
                console.log("捕获异常", err)
            })
        },
        getID(id, name) {
            this.v_id = id
            localStorage.setItem('v_name', name)
            localStorage.setItem('v_id',id)
            const banben = localStorage.getItem('v_name')
            this.v_name = banben
            this.equipmentQueryInfo()
        }
    },
    mounted() {
        this.v_name = localStorage.getItem('v_name')
    }
}
</script>

<style scoped>
.class_box {
    overflow: auto;
    max-height: 300px;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #6c757d;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: rgba(255, 255, 255, .7);
    padding: 10px 0;
}

.nav {
    height: 60px;
    background: #009688;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    width: 100%;
}

.el-button,
.el-dropdown {
    color: rgba(255, 255, 255, .7);
    transition: all 0.5s;
}

.el-button,
.el-dropdown:hover {
    color: #fff;
    transition: all 0.5s;
}

.menu {
    height: 100%;
    display: flex;
    align-items: center;
}

.menu ul {
    display: flex;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.menu ul li {
    list-style: none;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
}

.item {
    height: 100%;
    color: rgba(255, 255, 255, .7);
    transition: all 0.5s;
    display: flex;
    align-items: center;
    border-bottom: 4px solid rgb(95, 184, 120, 0);
}

.item:hover {
    color: #fff;
    border-bottom: 4px solid rgb(95, 184, 120);
    transition: all 0.5s;
}

.active {
    color: #fff;
    border-bottom: 4px solid rgb(95, 184, 120);
}</style>